import type {
  IMutateWorkspaceSettings,
  IWorkspaceSettings,
} from '@/shared/firebase/models/general-settings.interface';

import { Collection } from '../../constants/collection';
import {
  useCreateMutation,
  useGetById,
  useUpdateMutation,
} from '../helper/firebase-react-query';

type GetWorkspaceGeneralSetting = { key?: string };
export const useGetWorkspaceGeneralSetting = ({
  key,
}: GetWorkspaceGeneralSetting) => {
  return useGetById<IWorkspaceSettings>({
    collectionName: Collection.GENERAL_SETTINGS,
    id: 'workspace',
    key: key ?? 'useGetWorkspaceGeneralSetting',
  });
};

export const useCreateWorkspaceGeneralSetting = () => {
  return useCreateMutation<IMutateWorkspaceSettings>(
    Collection.GENERAL_SETTINGS,
    'workspace'
  );
};

export const useUpdateWorkspaceGeneralSetting = () => {
  return useUpdateMutation<IMutateWorkspaceSettings>(
    Collection.GENERAL_SETTINGS,
    'workspace'
  );
};
