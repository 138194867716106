/* eslint-disable @typescript-eslint/no-use-before-define */
import type {
  OrderByDirection,
  QueryConstraint,
  Timestamp,
} from 'firebase/firestore';
import { FieldPath, where } from 'firebase/firestore';

import type {
  IMutateWorkspaceMember,
  IWorkspaceMember,
} from '@/shared/firebase/models/workspace-member.interface';
import { compareDate } from '@/shared/utils/string';

import { Collection } from '../../constants/collection';
import {
  useBatchDeleteMutation,
  useBatchUpdateMutation,
  useCreateMutation,
  useDeleteMutation,
  useGetQuery,
  useUpdateMutation,
} from '../helper/firebase-react-query';

type GetWorkspaceMembers = {
  workspaceId?: string | null;
  userId?: string | null;
  sort?: {
    field: keyof Pick<IWorkspaceMember, 'createdAt'>;
    directionStr?: OrderByDirection;
  };
  key?: string;
};
export const useGetWorkspaceMembers = ({
  workspaceId,
  userId,
  sort = {
    field: 'createdAt',
  },
  key,
}: GetWorkspaceMembers) => {
  const queries: QueryConstraint[] = [];
  const keys: string[] = [
    sort.field,
    sort.directionStr ?? 'asc',
    key ?? 'useGetWorkspaceMembers',
  ];

  if (workspaceId) {
    queries.push(where(new FieldPath('workspace', 'id'), '==', workspaceId));
    keys.push(workspaceId);
  }

  if (userId) {
    queries.push(where(new FieldPath('user', 'id'), '==', userId));
    keys.push(userId);
  }

  return useGetQuery<IWorkspaceMember>({
    collectionName: Collection.WORKSPACE_MEMBERS,
    queryConstraint: queries,
    keys,
    useQueryOptions: {
      enabled: !!workspaceId || !!userId,
      select: (workspaceMembers: IWorkspaceMember[]) =>
        workspaceMembers.sort((before, next) =>
          compareDate(
            (before.createdAt as Timestamp).toDate(),
            (next.createdAt as Timestamp).toDate(),
            sort.directionStr ?? 'asc'
          )
        ),
    },
  });
};

export const useCreateWorkspaceMember = (id?: string) => {
  return useCreateMutation<IMutateWorkspaceMember>(
    Collection.WORKSPACE_MEMBERS,
    id
  );
};

export const useUpdateWorkspaceMember = (id?: string | null) => {
  return useUpdateMutation<IMutateWorkspaceMember>(
    Collection.WORKSPACE_MEMBERS,
    id
  );
};

export const useRemoveWorkspaceMember = (id?: string | null) => {
  return useDeleteMutation<IWorkspaceMember>(Collection.WORKSPACE_MEMBERS, id);
};

export const useBulkUpdateWorkspaceMember = (
  workspaceMembers: IMutateWorkspaceMember[]
) => {
  return useBatchUpdateMutation<IMutateWorkspaceMember>(
    Collection.WORKSPACE_MEMBERS,
    workspaceMembers
  );
};

export const useBulkDeleteForeverWorkspaceMembers = (ids: string[]) => {
  return useBatchDeleteMutation<IWorkspaceMember>(
    Collection.WORKSPACE_MEMBERS,
    ids
  );
};
