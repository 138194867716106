/* eslint-disable @typescript-eslint/no-use-before-define */
import type { QueryConstraint } from 'firebase/firestore';
import { orderBy, where } from 'firebase/firestore';

import type {
  IMutateReaction,
  IReaction,
} from '@/shared/firebase/models/reaction.interface';

import { Collection } from '../../constants/collection';
import {
  useCreateMutation,
  useDeleteMutation,
  useGetCountQuery,
  useGetQuery,
  useUpdateMutation,
} from '../helper/firebase-react-query';

type UseGetReactions = {
  itemId?: string | null;
  key?: string;
};
export const useGetReactions = ({ itemId, key }: UseGetReactions) => {
  const queries: QueryConstraint[] = [];
  const keys = [key ?? 'useGetReactions'];
  if (itemId) {
    queries.push(where('itemId', '==', itemId));
    queries.push(orderBy('timestamp', 'asc'));
    keys.push(itemId);
  }
  return useGetQuery<IReaction>({
    collectionName: Collection.REACTIONS,
    queryConstraint: queries,
    keys,
    useQueryOptions: {
      enabled: !!itemId,
    },
  });
};

type GetReactionsCount = {
  itemId?: string | null;
  key?: string;
};
export const useGetReactionsCount = ({ itemId, key }: GetReactionsCount) => {
  const queries: QueryConstraint[] = [];
  const keys = [key ?? 'useGetReactionsCount'];
  if (itemId) {
    queries.push(where('itemId', '==', itemId));
    keys.push(itemId);
  }
  return useGetCountQuery<IReaction>({
    collectionName: Collection.REACTIONS,
    queryConstraint: queries,
    keys,
    useQueryOptions: {
      enabled: !!itemId,
    },
  });
};

export const useCreateReaction = () => {
  return useCreateMutation<IMutateReaction>(Collection.REACTIONS);
};

export const useUpdateReaction = (id?: string | null) => {
  return useUpdateMutation<IMutateReaction>(Collection.REACTIONS, id);
};

export const useRemoveReaction = (id?: string | null) => {
  return useDeleteMutation<IReaction>(Collection.REACTIONS, id);
};
