import type { QueryConstraint } from 'firebase/firestore';

import type { IUser } from '@/shared/firebase/models/user.interface';

import { Collection } from '../../constants/collection';
import {
  useGetById,
  useGetQuery,
  useUpdateMutation,
} from '../helper/firebase-react-query';

type GetUsers = {
  key?: string;
};
export const useGetUsers = ({ key }: GetUsers) => {
  const queries: QueryConstraint[] = [];
  const keys: (string | object)[] = [key ?? 'useGetUsers'];
  return useGetQuery<IUser>({
    collectionName: Collection.USERS,
    queryConstraint: queries,
    keys,
    useQueryOptions: {
      enabled: true,
    },
  });
};

export const useGetUserById = (id?: string | null, key?: string) => {
  return useGetById<IUser>({
    collectionName: Collection.USERS,
    id,
    key: key ?? 'useGetUserById',
  });
};

export const useUpdateUser = (id?: string | null) => {
  return useUpdateMutation<IUser>(Collection.USERS, id);
};
