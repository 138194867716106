import type { QueryConstraint } from 'firebase/firestore';

import type { IPermission } from '@/shared/firebase/models/permission.interface';

import { Collection } from '../../constants/collection';
import { useGetQuery } from '../helper/firebase-react-query';

type GetPermissions = { key?: string };
export const useGetPermissions = ({ key }: GetPermissions) => {
  const queries: QueryConstraint[] = [];
  const keys: (string | object)[] = [key ?? 'useGetPermissions'];
  return useGetQuery<IPermission>({
    collectionName: Collection.PERMISSIONS,
    queryConstraint: queries,
    keys,
    useQueryOptions: {
      enabled: true,
    },
  });
};
