import type { QueryConstraint } from 'firebase/firestore';
import { FieldPath, where } from 'firebase/firestore';

import type { ISubscription } from '@/shared/firebase/models/subscription.interface';

import { Collection } from '../../constants/collection';
import { useGetQuery } from '../helper/firebase-react-query';

type GetSubscriptionArgs = {
  workspaceId?: string | null;
  userId?: string | null;
  key?: string;
};
export const useGetSubscriptions = ({
  userId,
  workspaceId,
  key,
}: GetSubscriptionArgs) => {
  const queries: QueryConstraint[] = [];
  const keys: (string | object)[] = [key ?? 'useGetSubscriptions'];

  if (workspaceId) {
    queries.push(where(new FieldPath('workspace', 'id'), '==', workspaceId));
    keys.push(workspaceId);
  }

  if (userId) {
    queries.push(where(new FieldPath('user', 'id'), '==', userId));
    keys.push(userId);
  }

  return useGetQuery<ISubscription>({
    collectionName: Collection.SUBSCRIPTIONS,
    queryConstraint: queries,
    keys,
    useQueryOptions: {
      enabled: !!workspaceId || !!userId,
    },
  });
};
