import type { OrderByDirection, QueryConstraint } from 'firebase/firestore';
import {
  FieldPath,
  limit,
  orderBy,
  Timestamp,
  where,
} from 'firebase/firestore';
import moment from 'moment';

import type {
  IMutateView,
  IView,
} from '@/shared/firebase/models/view.interface';

import { Collection } from '../../constants/collection';
import {
  useBatchUpdateMutation,
  useCreateMutation,
  useGetInfinityQuery,
  useGetQuery,
  useUpdateMutation,
} from '../helper/firebase-react-query';

type GetViews = {
  itemId?: string;
  userId?: string;
  key?: string;
};
export const useGetViews = ({ itemId, userId, key }: GetViews) => {
  const queries: QueryConstraint[] = [];
  const keys = [key ?? 'useGetViews'];
  if (itemId) {
    queries.push(where('itemId', '==', itemId));
    keys.push(itemId);
  }

  if (userId) {
    queries.push(where(new FieldPath('user', 'id'), '==', userId));
    keys.push(userId);
  }

  return useGetQuery<IView>({
    collectionName: Collection.VIEWS,
    queryConstraint: queries,
    keys,
    useQueryOptions: {
      enabled: !!itemId || !!userId,
    },
  });
};

type GetViewsHistory = {
  userId?: string;
  workspaceId?: string;
  endTime?: Timestamp;
  startTime?: Timestamp;
  key?: string;
};

export const useGetViewsHistory = ({
  userId,
  workspaceId,
  endTime,
  startTime,
  key,
}: GetViewsHistory) => {
  const queries: QueryConstraint[] = [where('showInHistory', '==', true)];
  const keys = [key ?? 'useGetViewsHistory'];
  if (endTime) {
    queries.push(where('updatedAt', '>=', endTime));
    queries.push(
      where(
        'updatedAt',
        '<=',
        startTime ?? Timestamp.fromMillis(moment().valueOf())
      )
    );
    keys.push(startTime?.toString() ?? '', endTime.toString());
  }

  if (userId) {
    queries.push(where(new FieldPath('user', 'id'), '==', userId));
    keys.push(userId);
  }

  if (workspaceId) {
    queries.push(where('workspaceId', '==', workspaceId));
    keys.push(workspaceId);
  }

  return useGetQuery<IView>({
    collectionName: Collection.VIEWS,
    queryConstraint: queries,
    keys,
    useQueryOptions: {
      enabled: !!workspaceId || !!userId,
    },
  });
};

type GetInfinityViews = {
  userId?: string | null;
  workspaceId?: string | null;
  sort?: {
    field: keyof Pick<IView, 'count' | 'updatedAt'>;
    directionStr?: OrderByDirection;
  };
  key?: string;
};

export const useGetInfinityViews = ({
  userId,
  workspaceId,
  sort = { field: 'updatedAt', directionStr: 'desc' },
  key,
}: GetInfinityViews) => {
  const queries: QueryConstraint[] = [
    where('showInHistory', '==', true),
    limit(5),
    orderBy(sort.field, sort.directionStr ?? 'desc'),
  ];

  const keys: (string | object)[] = [
    sort.field,
    sort.directionStr ?? 'desc',
    key ?? 'useGetInfinityViews',
  ];

  if (workspaceId) {
    queries.push(where('workspaceId', '==', workspaceId));
    keys.push(workspaceId);
  }
  if (userId) {
    queries.push(where(new FieldPath('user', 'id'), '==', userId));
    keys.push(userId);
  }

  return useGetInfinityQuery<IView>({
    collectionName: Collection.VIEWS,
    keys,
    queryConstraint: queries,
    useInfiniteQueryOptions: {
      enabled: !!workspaceId && !!userId,
    },
  });
};

export const useGetAllViews = ({
  userId,
  workspaceId,
  sort = { field: 'updatedAt', directionStr: 'desc' },
  key,
}: GetInfinityViews) => {
  const queries: QueryConstraint[] = [
    where('showInHistory', '==', true),
    orderBy(sort.field, sort.directionStr ?? 'desc'),
  ];

  const keys: (string | object)[] = [
    sort.field,
    sort.directionStr ?? 'desc',
    key ?? 'useGetAllViews',
  ];

  if (workspaceId) {
    queries.push(where('workspaceId', '==', workspaceId));
    keys.push(workspaceId);
  }
  if (userId) {
    queries.push(where(new FieldPath('user', 'id'), '==', userId));
    keys.push(userId);
  }

  return useGetQuery<IView>({
    collectionName: Collection.VIEWS,
    keys,
    queryConstraint: queries,
    useQueryOptions: {
      enabled: !!workspaceId && !!userId,
    },
  });
};

export const useCreateView = () => {
  return useCreateMutation<IMutateView>(Collection.VIEWS);
};

export const useUpdateView = (id?: string | null) => {
  return useUpdateMutation<IMutateView>(Collection.VIEWS, id);
};

export const useBulkClearViewHistory = (views: IView[]) => {
  return useBatchUpdateMutation<IView>(
    Collection.VIEWS,
    views.map((view) => ({
      ...view,
      showInHistory: false,
    }))
  );
};
