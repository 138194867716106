import type { OrderByDirection, QueryConstraint } from 'firebase/firestore';
import { orderBy } from 'firebase/firestore';

import type {
  IContact,
  IContactCreate,
} from '@/shared/firebase/models/contact.interface';

import { Collection } from '../../constants/collection';
import {
  useCreateMutation,
  useDeleteMutation,
  useGetQuery,
} from '../helper/firebase-react-query';

type UseGetContacts = {
  sort?: {
    field: keyof Pick<IContact, 'createdAt'>;
    directionStr?: OrderByDirection;
  };
  key?: string;
};
export const useGetContacts = ({
  sort = { field: 'createdAt' },
  key,
}: UseGetContacts) => {
  const queries: QueryConstraint[] = [
    orderBy(sort.field, sort.directionStr ?? 'desc'),
  ];
  const keys: (string | object)[] = [key ?? 'useGetContacts'];
  return useGetQuery<IContact>({
    collectionName: Collection.CONTACTS,
    queryConstraint: queries,
    keys,
    useQueryOptions: {
      enabled: true,
    },
  });
};

export const useCreateContact = () => {
  return useCreateMutation<IContactCreate>(Collection.CONTACTS);
};

export const useRemoveContact = (id?: string | null) => {
  return useDeleteMutation<IContact>(Collection.CONTACTS, id);
};
