import type { QueryConstraint } from 'firebase/firestore';
import { where } from 'firebase/firestore';

import type { IPlan } from '@/shared/firebase/models/stripe-payment.interface';

import { Collection } from '../../constants/collection';
import { useGetById, useGetQuery } from '../helper/firebase-react-query';

type GetPlans = {
  isCustom?: boolean;
  key?: string;
};
export const useGetPlans = ({ isCustom, key }: GetPlans) => {
  const queries: QueryConstraint[] = [];
  const keys: string[] = [key ?? 'useGetPlans'];
  if (isCustom !== undefined) {
    queries.push(where('custom', '==', isCustom));
    keys.push('custom');
  } else {
    keys.push('all');
  }
  return useGetQuery<IPlan>({
    collectionName: Collection.PLANS,
    queryConstraint: queries,
    keys,
    useQueryOptions: {
      enabled: true,
    },
  });
};

export const useGetPlanById = (id?: string | null, key?: string) => {
  return useGetById<IPlan>({
    collectionName: Collection.PLANS,
    id,
    key: key ?? 'useGetPlanById',
  });
};
