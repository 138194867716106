import { FieldPath, type QueryConstraint, where } from 'firebase/firestore';

import { Collection } from '../../constants';
import type { StripePaymentMethod } from '../../models/payment-method.interface';
import { useGetById, useGetQuery } from '../helper/firebase-react-query';

type GetPaymentMethodsArgs = {
  userId?: string | null;
  key?: string;
};
export const useGetPaymentMethods = ({
  userId,
  key,
}: GetPaymentMethodsArgs) => {
  const queries: QueryConstraint[] = [];
  const keys: (string | object)[] = [key ?? 'useGetPaymentMethods'];

  if (userId) {
    queries.push(where(new FieldPath('user', 'id'), '==', userId));
    keys.push(userId);
  }
  return useGetQuery<StripePaymentMethod>({
    collectionName: Collection.PAYMENT_METHODS,
    queryConstraint: queries,
    keys,
    useQueryOptions: {
      enabled: !!userId,
    },
  });
};

export const useGetPaymentMethodsById = (id?: string | null, key?: string) => {
  return useGetById<StripePaymentMethod>({
    collectionName: Collection.PAYMENT_METHODS,
    id,
    key: key ?? 'useGetPaymentMethodsById',
  });
};
