import type { QueryConstraint } from 'firebase/firestore';

import type {
  IMutateRole,
  IRole,
} from '@/shared/firebase/models/role.interface';

import { Collection } from '../../constants/collection';
import {
  useCreateMutation,
  useDeleteMutation,
  useGetQuery,
  useUpdateMutation,
} from '../helper/firebase-react-query';

type GetRoles = { key?: string };
export const useGetRoles = ({ key }: GetRoles) => {
  const queries: QueryConstraint[] = [];
  const keys: (string | object)[] = [key ?? 'useGetRoles'];
  return useGetQuery<IRole>({
    collectionName: Collection.ROLES,
    queryConstraint: queries,
    keys,
    useQueryOptions: {
      enabled: true,
    },
  });
};

export const useCreateRole = () => {
  return useCreateMutation<IMutateRole>(Collection.ROLES);
};

export const useUpdateRole = (id?: string | null) => {
  return useUpdateMutation<IMutateRole>(Collection.ROLES, id);
};

export const useRemoveRole = (id?: string | null) => {
  return useDeleteMutation<IRole>(Collection.ROLES, id);
};
