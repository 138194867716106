import type { OrderByDirection, QueryConstraint } from 'firebase/firestore';
import { limit, orderBy, where } from 'firebase/firestore';

import type {
  INotification,
  INotificationUpdate,
} from '@/shared/firebase/models/notification.interface';

import { Collection } from '../../constants/collection';
import {
  useBatchDeleteMutation,
  useBatchUpdateMutation,
  useDeleteMutation,
  useGetQuery,
  useUpdateMutation,
} from '../helper/firebase-react-query';

export type FilterNotificationBy = 'all' | 'unread' | Collection.COMMENTS;
type GetNotifications = {
  email?: string | null;
  sort?: {
    field: keyof Pick<INotification, 'createdAt'>;
    directionStr?: OrderByDirection;
  };
  filterBy?: FilterNotificationBy;
  max?: number;
  key?: string;
};
export const useGetNotifications = ({
  email,
  sort = { field: 'createdAt' },
  max,
  filterBy,
  key,
}: GetNotifications) => {
  const queries: QueryConstraint[] = [];
  const keys: string[] = [
    sort.field,
    sort.directionStr ?? 'desc',
    key ?? 'useGetNotifications',
  ];

  queries.push(orderBy(sort.field, sort.directionStr ?? 'desc'));

  if (email) {
    queries.push(where('email', '==', email));
    keys.push(email);
  }

  if (max) {
    queries.push(limit(max));
  }

  if (filterBy === Collection.COMMENTS) {
    queries.push(where('type', '==', Collection.COMMENTS));
    keys.push(filterBy);
  }

  if (filterBy === 'unread') {
    queries.push(where('readStatus', '==', false));
    keys.push(filterBy);
  }

  return useGetQuery<INotification>({
    collectionName: Collection.NOTIFICATIONS,
    queryConstraint: queries,
    keys,
    useQueryOptions: {
      enabled: !!email,
      select: (notifications) =>
        notifications.filter(
          (notification) => notification.type !== Collection.INVITATIONS
        ),
    },
  });
};

export const useGetInvitationNotifications = ({
  email,
  key,
}: GetNotifications) => {
  const queries: QueryConstraint[] = [
    where('type', '==', Collection.INVITATIONS),
  ];
  const keys: string[] = [key ?? 'useGetInvitationNotifications'];

  if (email) {
    queries.push(where('email', '==', email));
    keys.push(email);
  }

  return useGetQuery<INotification>({
    collectionName: Collection.NOTIFICATIONS,
    queryConstraint: queries,
    keys,
    useQueryOptions: {
      enabled: !!email,
    },
  });
};

export const useUpdateNotification = (id?: string | null) => {
  return useUpdateMutation<INotificationUpdate>(Collection.NOTIFICATIONS, id);
};

export const useRemoveNotification = (id?: string) => {
  return useDeleteMutation<INotification>(Collection.NOTIFICATIONS, id);
};

export const useBulkUpdateNotifications = (notifications: INotification[]) => {
  return useBatchUpdateMutation<INotification>(
    Collection.NOTIFICATIONS,
    notifications.map((notification) => ({
      ...notification,
      readStatus: true,
    }))
  );
};

export const useBulkDeleteForeverNotifications = (ids: string[]) => {
  return useBatchDeleteMutation<INotification>(Collection.NOTIFICATIONS, ids);
};
