import type {
  OrderByDirection,
  QueryConstraint,
  QueryFilterConstraint,
  Timestamp,
} from 'firebase/firestore';
import { FieldPath, or, where } from 'firebase/firestore';

import type {
  ICreateInvitation,
  IInvitation,
} from '@/shared/firebase/models/invitation.interface';
import { compareDate } from '@/shared/utils/string';

import { Collection } from '../../constants/collection';
import {
  useBatchCreateMutation,
  useCreateMutation,
  useDeleteMutation,
  useGetById,
  useGetQuery,
  useUpdateMutation,
} from '../helper/firebase-react-query';

type GetInvitationArgs = {
  id?: string;
  email?: string | null;
  workspaceId?: string | null;
  sort?: {
    field: keyof Pick<IInvitation, 'createdAt'>;
    directionStr?: OrderByDirection;
  };
  key?: string;
};

export const useGetInvitations = ({
  id,
  email,
  workspaceId,
  sort = {
    field: 'createdAt',
  },
  key,
}: GetInvitationArgs) => {
  const queries: QueryConstraint[] = [];
  const orQueries: QueryFilterConstraint[] = [];

  const keys: string[] = [
    sort.field,
    sort.directionStr ?? 'desc',
    key ?? 'useGetInvitations',
  ];
  if (id) {
    orQueries.push(where('id', '==', id));
  }

  if (email) {
    orQueries.push(where('email', '==', email));
    keys.push(email);
  }

  if (workspaceId) {
    queries.push(where(new FieldPath('workspace', 'id'), '==', workspaceId));
    keys.push(workspaceId);
  }

  return useGetQuery<IInvitation>({
    collectionName: Collection.INVITATIONS,
    compositeFilter: or(...orQueries),
    queryConstraint: [],
    useQueryOptions: {
      enabled: email !== undefined,
      select: (invitations: IInvitation[]) =>
        invitations.sort((before, next) =>
          compareDate(
            (before.createdAt as Timestamp).toDate(),
            (next.createdAt as Timestamp).toDate(),
            sort.directionStr ?? 'asc'
          )
        ),
    },
    keys,
  });
};

type GetInvitationsByWorkspaceArgs = {
  workspaceId?: string | null;
  sort?: {
    field: keyof Pick<IInvitation, 'createdAt'>;
    directionStr?: OrderByDirection;
  };
  key?: string;
};

export const useGetInvitationsByWorkspace = ({
  workspaceId,
  sort = {
    field: 'createdAt',
  },
  key,
}: GetInvitationsByWorkspaceArgs) => {
  const queries: QueryConstraint[] = [];

  const keys: string[] = [
    sort.field,
    sort.directionStr ?? 'desc',
    key ?? 'useGetInvitationsByWorkspace',
  ];

  if (workspaceId) {
    queries.push(where(new FieldPath('workspace', 'id'), '==', workspaceId));
    keys.push(workspaceId);
  }

  return useGetQuery<IInvitation>({
    collectionName: Collection.INVITATIONS,
    queryConstraint: queries,
    useQueryOptions: {
      enabled: !!workspaceId,
      select: (invitations: IInvitation[]) =>
        invitations.sort((before, next) =>
          compareDate(
            (before.createdAt as Timestamp).toDate(),
            (next.createdAt as Timestamp).toDate(),
            sort.directionStr ?? 'asc'
          )
        ),
    },
    keys,
  });
};

export const useGetInvitationById = (id?: string | null, key?: string) => {
  return useGetById<IInvitation>({
    collectionName: Collection.INVITATIONS,
    id,
    key: key ?? 'useGetInvitationById',
  });
};
export const useCreateInvitation = () => {
  return useCreateMutation<ICreateInvitation>(Collection.INVITATIONS);
};

export const useUpdateInvitation = (id: string) => {
  return useUpdateMutation<IInvitation>(Collection.INVITATIONS, id);
};

export const useBatchCreateInvitation = (invitations: ICreateInvitation[]) => {
  return useBatchCreateMutation<ICreateInvitation>(
    Collection.INVITATIONS,
    invitations
  );
};

export const useRemoveInvitation = (id?: string | null) => {
  return useDeleteMutation<IInvitation>(Collection.INVITATIONS, id);
};
