import type { OrderByDirection, QueryConstraint } from 'firebase/firestore';
import { orderBy, where } from 'firebase/firestore';

import type {
  IComment,
  IMutateComment,
} from '@/shared/firebase/models/comment.interface';

import { Collection } from '../../constants/collection';
import {
  useCreateMutation,
  useDeleteMutation,
  useGetById,
  useGetCountQuery,
  useGetQuery,
  useUpdateMutation,
} from '../helper/firebase-react-query';

type UseGetComments = {
  itemId: string;
  sort?: {
    field: keyof Pick<IComment, 'createdAt'>;
    directionStr?: OrderByDirection;
  };
  key?: string;
};
export const useGetComments = ({
  itemId,
  sort = {
    field: 'createdAt',
  },
  key,
}: UseGetComments) => {
  const queries: QueryConstraint[] = [
    where('itemId', '==', itemId),
    orderBy(sort.field, sort.directionStr ?? 'desc'),
  ];
  const keys = [
    itemId,
    sort.field,
    sort.directionStr ?? 'desc',
    key ?? 'useGetComments',
  ];
  return useGetQuery<IComment>({
    collectionName: Collection.COMMENTS,
    queryConstraint: queries,
    keys,
    useQueryOptions: {
      enabled: !!itemId,
    },
  });
};
type UseGetCommentsCount = {
  itemId: string;
  key?: string;
};
export const useGetCommentsCount = ({ itemId, key }: UseGetCommentsCount) => {
  const queries: QueryConstraint[] = [where('itemId', '==', itemId)];
  const keys = [itemId, key ?? 'useGetCommentsCount'];
  return useGetCountQuery<IComment>({
    collectionName: Collection.COMMENTS,
    queryConstraint: queries,
    keys,
    useQueryOptions: {
      enabled: !!itemId,
    },
  });
};

export const useGetCommentById = (id?: string | null, key?: string) => {
  return useGetById<IComment>({
    collectionName: Collection.COMMENTS,
    id,
    key: key ?? 'useGetCommentById',
  });
};

export const useCreateComment = () => {
  return useCreateMutation<IMutateComment>(Collection.COMMENTS);
};

export const useUpdateComment = (id?: string | null) => {
  return useUpdateMutation<IMutateComment>(Collection.COMMENTS, id);
};

export const useRemoveComment = (id?: string | null) => {
  return useDeleteMutation<IComment>(Collection.COMMENTS, id);
};
