import type { QueryConstraint } from 'firebase/firestore';
import { FieldPath, where } from 'firebase/firestore';

import type {
  IWorkspace,
  IWorkspaceCreate,
} from '@/shared/firebase/models/workspace.interface';

import { Collection } from '../../constants/collection';
import {
  useCreateMutation,
  useDeleteMutation,
  useGetById,
  useGetQuery,
  useUpdateMutation,
} from '../helper/firebase-react-query';

type GetWorkspaces = {
  userId?: string | null;
  isPersonal?: boolean;
  key?: string;
};

export const useGetWorkspaces = ({
  userId,
  isPersonal,
  key,
}: GetWorkspaces) => {
  const queries: QueryConstraint[] = [];
  const keys = [key ?? 'useGetWorkspaces'];

  if (userId) {
    queries.push(where(new FieldPath('user', 'id'), '==', userId));
    keys.push(userId);
  }

  if (isPersonal !== undefined) {
    queries.push(where('personal', '==', true));
    keys.push('personal');
  }

  return useGetQuery<IWorkspace>({
    collectionName: Collection.WORKSPACES,
    queryConstraint: queries,
    keys,
    useQueryOptions: {
      enabled: !!userId || isPersonal !== undefined,
    },
  });
};

export const useGetWorkspaceById = (id?: string | null, key?: string) => {
  return useGetById<IWorkspace>({
    collectionName: Collection.WORKSPACES,
    id,
    key: key ?? 'useGetWorkspaceById',
  });
};

export const useCreateWorkspace = (id: string) => {
  return useCreateMutation<IWorkspaceCreate>(Collection.WORKSPACES, id);
};

export const useUpdateWorkspace = (id?: string | null) => {
  return useUpdateMutation<IWorkspaceCreate>(Collection.WORKSPACES, id);
};

export const useRemoveWorkspace = (id?: string | null) => {
  return useDeleteMutation<IWorkspace>(Collection.WORKSPACES, id);
};
